﻿import { Injectable } from '@angular/core';
import { ProxyService } from './proxy-service';
import { IOrderStatusHistoryResponse } from '../models/order-status-history';
import { IDeliveryStatusHistoryResponse } from '../models/delivery-status-history';
import { Subject } from 'rxjs/Subject';
import { AppAttribute } from '../models/appattribute';
import { ContextService } from './context-service';
import { IEditOrderItemUI, IGetOrderItemByUpcAndOrderResponse, IOrderItem, IOrderItemListResponse, IOrderItemResponse } from '../models/order';
import { SpinnerService } from './spinner-service';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from 'src/app/@shared/models/response';

@Injectable()
export class OrderService {

  reserveOrderSubject$ = new Subject();
  orderPromiseTimeSubject$ = new Subject();
  cancelOrdersSubject$ = new Subject();
  completeOrdersSubject$ = new Subject();

  constructor(
    private proxyService: ProxyService,
    private contextService: ContextService,
    private spinnerService: SpinnerService,
    private toastrService: ToastrService
  ) {}

  async showReserveUsers(storeId: number) {
    return await this.proxyService.getCore(`user/shoppersanddriversbystore/${storeId}`, storeId);
  }

  async orderUserGroup(orderId: number, storeId: number) {
    return await this.proxyService.getCore(`orderusergroups/getAssigmentByOrderCustomer/${orderId}`, storeId);
  }

  async reserveOrder(storeId: number, orderId: number, userReserves: string[]) {
    await this.proxyService.postCore('orderusergroups', { orderIds: [orderId], userIds: userReserves }, storeId);
  }

  public async cancelOrder(storeId: number, orderId: number, orderComment: string) {
    await this.proxyService.postCore('orders/cancel', { orderId, cancelComment: orderComment }, storeId);
  }

  public async updateOrderStatus(storeId: number, orderId: number, newOrderStatusId: number, updateComment: string) {
    await this.proxyService.putCore('orders/status', { orderId, newOrderStatusId, updateComment }, storeId);
  }

  public async updateOrderPromiseTime(storeId: number, orderId: number, orderInspectorFactId: number, promiseTime: string): Promise<any> {
    return await this.proxyService.put('orders/promisetime', { orderId, storeId, orderInspectorFactId, promiseTime });
  }

  async getOrderStatusHistory(storeId: number, orderId: number): Promise<IOrderStatusHistoryResponse> {
    const response: IOrderStatusHistoryResponse = await this.proxyService.getCore(`orderStatusHistory/order/${orderId}`, storeId);
    return response;
  }

  async getDeliveryStatusHistory(storeId: number, orderId: number): Promise<IDeliveryStatusHistoryResponse> {
    const response: IDeliveryStatusHistoryResponse = await this.proxyService.getCore(`orders/${orderId}/deliverystatushistory`, storeId);
    return response;
  }

  async sendDeliveryRetry(storeId: number, orderId: number): Promise<any> {
    return await this.proxyService.postCore(`store/delivery/retry`, orderId, storeId);
  }

  async sendSubsApprovalLink(storeId: number, orderId: number): Promise<any> {
    const response = await this.proxyService.postCore(`orders/approval/${orderId}/send`, null, storeId);
    return response;
  }

  async getAppAtribute(): Promise<AppAttribute[]> {
    const clientId = this.contextService.userContext().clientId;
    const response: AppAttribute[] =  await this.proxyService.getCore('client/appattributes/' + clientId, 0);
    return response;
  }

  async getAppAtributeByName(attributeName: string): Promise<AppAttribute> {
    const clientId = this.contextService.userContext().clientId;
    const response: AppAttribute =  await this.proxyService.getCore(`client/${clientId}/appattributes/${attributeName}`, 0);
    return response;
  }

  async getOrderItemByUpcAndOrderId(upc: string, orderId: number, storeId: number): Promise<IGetOrderItemByUpcAndOrderResponse> {
    try {
      const response =  await this.proxyService.getCore(`orderitems/upc/${upc}/order/${orderId}`, storeId);
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async addNewOrderItem(orderItem: IOrderItem, storeId: number): Promise<IOrderItemResponse> {
    try {
      const response = await this.proxyService.putCore('orderitems/create/true', orderItem, storeId);
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getOrderItemsByOrderIdAndStatusId(orderId: number, orderItemStatusId: number, storeId: number): Promise<IOrderItemListResponse> {
    try {
      const response =  await this.proxyService.getCore(`orderitems/order/${orderId}/status/${orderItemStatusId}`, storeId);
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async updateOrderItems(items: IEditOrderItemUI[], storeId: number): Promise<IOrderItemListResponse> {
    try {
      const itemsList = items.filter(it => (it.qty != it.quantity || (it.requireWeight && it.wgt != it.weight)));
      const itemsToUpdate = [];
      itemsList.forEach(it => {
        itemsToUpdate.push({
          orderItemId: it.orderItemId,
          quantity: +it.qty,
          requireWeight: it.requireWeight,
          weight: it.requireWeight && it.wgt ? parseFloat(it.wgt.toString()) : null
        });
      });
      const response = await this.proxyService.putCore(`orderitems/BatchUpdateQtyWeight/true`, {
        orderItems: itemsToUpdate
      }, storeId);
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async deleteOrderItems(items: IEditOrderItemUI[], storeId: number): Promise<any> {
    try {
      var orderId = items[0].orderId;
      const itemsListIds = items.filter(it => it.selected).map(it => it.orderItemId);
      const response = await this.proxyService.deleteCore(`orderitems`, {
        orderId,
        orderItemIds: itemsListIds
      }, storeId);
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async exportOrderSummaries(storeId: number, orderIds: number[]) {
    this.spinnerService.show('Exporting Summaries');
    const response = await this.proxyService.postCore('renderTemplate/shoppedOrderSummary/bulk', { orderIds }, storeId);
    const win = window.open();
    if (null == window) {
      this.toastrService.error('Please disable your popup blocker for and export again.');
    } else {
      const title = 'Shopped Order Summaries';
      const html = `<html><head><title>${title}</title></head><body>${response.html}</body></html>`;
      win.document.write(html);
    }
    this.spinnerService.hide();
  }

  async syncOrder(orderId: number, storeId: number, clientId: number): Promise<IResponse> {
    const response: IResponse = await this.proxyService.post('orders/sync', {
      orderId,
      storeId,
      clientId
    });   
    return response;
  }
}
